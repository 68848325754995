import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/uv`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour obtenir toutes les UV
export const getAllUV = async () => {
  try {
    const response = await axios.get(`${apiUrl}/alluvs`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération des UV:", error);
  }
};

// Fonction pour obtenir une UV par l'id de l'adéhrent
export const getUVById = async (adherentId) => {
  try {
    const response = await axios.get(`${apiUrl}/adherent_uvs/${adherentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la récupération de l'UV:", error);
  }
};

/// POST
// Fonction pour créer une nouvelle UV
export const createUV = async (uvData) => {
  try {
    const response = await axios.post(`${apiUrl}/createuv`, uvData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la création de l'UV:", error);
  }
};

/// PUT
// Fonction pour modifier une UV
export const updateUV = async (uvId, uvData) => {
  try {
    const response = await axios.put(`${apiUrl}/updateuv/${uvId}`, uvData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la modification de l'UV:", error);
  }
};

/// DELETE
// Fonction pour supprimer une UV
export const deleteUV = async (uvId) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteuv/${uvId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("Réponse:", response.data);
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de l'UV:", error);
  }
};

