import React, { useEffect, useState } from "react";
import { getUVById } from "./../../../services/uvServices";

function AdherentUV({ adherentId }) {
  const [uvs, setUVs] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (adherentId) {
        try {
          const uvData = await getUVById(adherentId);
          setUVs(uvData);
        } catch (error) {
          console.error("Erreur lors de la récupération des UVs :", error);
          setUVs(null);
        }
      }
    }

    fetchData();
  }, [adherentId]);

  const renderUvIcon = (value) => {
    return value ? "☑️" : "🔲";
  };

  if (!uvs) {
    return null;
  }

  return (
    <div className="bg-gray-100 dark:bg-gray-500 px-6 pt-2 pb-4 shadow-lg max-w-[800px] mx-auto font-montserrat">
      <div className="bg-gray-300 dark:bg-gray-600 p-4 rounded">
        <h3 className="text-2xl font-light uppercase mb-4 text-left">
          Validation des UV
        </h3>
        <div className="space-y-4">
          <div className="bg-white dark:bg-slate-800 rounded-lg shadow-lg p-4">
            <div className="flex flex-wrap justify-between text-sm text-gray-700 dark:text-gray-300">
              {/* Première ligne : UV1 à UV5 */}
              <div className="flex flex-row w-full justify-between">
                {Array.from({ length: 5 }, (_, i) => (
                  <div key={`uv${i + 1}`} className="flex items-center gap-2">
                    <span className="font-semibold">UV{i + 1} :</span>
                    <span>{renderUvIcon(uvs[`uv${i + 1}`])}</span>
                  </div>
                ))}
              </div>

              {/* Deuxième ligne : UV6 à UV9 */}
              <div className="flex flex-row w-full justify-between mt-4">
                {Array.from({ length: 4 }, (_, i) => (
                  <div key={`uv${i + 6}`} className="flex items-center gap-2">
                    <span className="font-semibold">UV{i + 6} :</span>
                    <span>{renderUvIcon(uvs[`uv${i + 6}`])}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdherentUV;
