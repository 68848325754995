import React, { useState, useEffect } from "react";
import { useUser } from "./../../Home/UserContext";
import { getRoleFromRoleId } from "./../../../utils/roleHelper";
import DashboardNavigation from "./../../DashboardNavigation/DashboardNavigation";
import {
  getAllUV,
  deleteUV,
  updateUV,
  createUV,
} from "./../../../services/uvServices";
import { getAllAdherentsList } from "./../../../services/adherentServices";
import useScrollToTop from "./../../../hooks/useScollToTop";
import { ToastContainer, toast } from "react-toastify";
import Button from "./../../../components/Button/Button";
import "react-toastify/dist/ReactToastify.css";
import ButtonDelete from "./../../../components/Button/ButtonDelete";
import InfoBubble from "./../../../components/InfoBubble/InfoBubble";

const UVPage = () => {
  useScrollToTop();
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const [uvData, setUVData] = useState([]);
  const [adherentList, setAdherentList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [selectedAdherent, setSelectedAdherent] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchUV = async () => {
      try {
        const data = await getAllUV();
        if (Array.isArray(data)) {
          setUVData(data);
          // Extraire les groupes pour le filtre
          const groups = new Set(
            data.flatMap((item) =>
              item.adherent?.adherentGroups?.map((group) => group.group?.nom)
            )
          );
          setGroupList([...groups]);
        } else {
          throw new Error("Les données reçues ne sont pas un tableau");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des UV:", error);
        toast.error("Erreur lors de la récupération des UV");
      }
    };

    const fetchAdherents = async () => {
      try {
        const adherents = await getAllAdherentsList();
        setAdherentList(adherents);
      } catch (error) {
        console.error("Erreur lors de la récupération des adhérents:", error);
        toast.error("Erreur lors de la récupération des adhérents");
      }
    };

    fetchUV();
    fetchAdherents();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleGroupFilterChange = (e) => {
    setSelectedGroup(e.target.value);
  };

  const handleUVClick = async (uvId, field, currentValue) => {
    try {
      const updatedData = { [field]: !currentValue };
      await updateUV(uvId, updatedData);
      setUVData((prevUV) =>
        prevUV.map((uv) =>
          uv.id === uvId ? { ...uv, [field]: !currentValue } : uv
        )
      );
      toast.success("UV mise à jour avec succès");
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'UV:", error);
      toast.error("Erreur lors de la mise à jour de l'UV");
    }
  };

  const handleCreateUV = async () => {
    if (!selectedAdherent) {
      toast.error("Veuillez sélectionner un adhérent.");
      return;
    }

    // Vérifier si une fiche UV existe déjà
    const existingUV = uvData.find(
      (uv) => uv.adherent_id === parseInt(selectedAdherent, 10)
    );

    if (existingUV) {
      toast.error("Une fiche UV existe déjà pour cet adhérent.");
      return;
    }

    try {
      await createUV({ adherent_id: selectedAdherent });
      toast.success("Fiche UV créée avec succès");
      setSelectedAdherent("");
      const updatedUVs = await getAllUV();
      setUVData(updatedUVs);
    } catch (error) {
      console.error("Erreur lors de la création de la fiche UV:", error);
      toast.error("Erreur lors de la création de la fiche UV");
    }
  };

  const onDeleteUV = async (uvId) => {
    if (!uvId) {
      toast.error("Aucune fiche UV sélectionnée pour la suppression.");
      return;
    }

    const confirmDelete = window.confirm(
      "Êtes-vous sûr de vouloir supprimer cette fiche UV ?"
    );
    if (!confirmDelete) {
      return;
    }

    try {
      await deleteUV(uvId); // Appelle l'API pour supprimer la fiche UV
      setUVData((prevUV) => prevUV.filter((uv) => uv.id !== uvId)); // Met à jour les données localement
      toast.success("Fiche UV supprimée avec succès.");
    } catch (error) {
      console.error("Erreur lors de la suppression de la fiche UV :", error);
      toast.error("Erreur lors de la suppression de la fiche UV.");
    }
  };

  const filteredUV = uvData.filter((item) => {
    const matchesSearchTerm = searchTerm
      ? item.adherent?.nom?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.adherent?.prenom?.toLowerCase().includes(searchTerm.toLowerCase())
      : true;

    const matchesGroup =
      selectedGroup && item.adherent?.adherentGroups
        ? item.adherent.adherentGroups.some(
            (group) => group.group?.nom === selectedGroup
          )
        : true;

    return matchesSearchTerm && matchesGroup;
  });

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] dark:text-white">
      <h2 className="text-xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        ENSEIGNANT
      </h2>
      <h2 className="text-xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Validation des UV
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="flex flex-col sm:flex-col items-center justify-between w-full px-4 mt-4 space-y-2 sm:space-y-0 ">
        {/* Informations sur les UVs et les ceintures */}
        <InfoBubble title="Informations sur les UVs">
          <div className="flex space-x-4">
            <div>
              <p className="mb-2">
                <strong className="dark:text-white">Nous avons 9 UVs :</strong>
              </p>
              <ul className="mb-4 list-disc list-inside">
                <li>UV1 : Arbitrage et culture</li>
                <li>UV2 : Tachi-waza</li>
                <li>UV3 : Ne-waza</li>
                <li>UV4 : Taïso</li>
                <li>UV5 : Epreuve de combat</li>
                <li>UV6 : Kata</li>
                <li>UV7 : Méthodes d'entraînement</li>
                <li>UV8 : Ju Jitsu</li>
                <li>UV9 : Epreuve pédagogique</li>
              </ul>
            </div>
            <div>
              <p className="mb-2">
                <strong className="dark:text-white">
                  Nombre d'UVs par ceintures :
                </strong>
              </p>
              <ul className="list-disc list-inside">
                <li>Ceinture Blanche/Jaune : UV1 à UV4</li>
                <li>Ceinture Jaune à Orange/verte : UV1 à UV5</li>
                <li>Ceinture verte et verte/bleue : UV1 à UV6</li>
                <li>Ceinture Bleue : UV1 à UV8</li>
                <li>Ceinture Marron : UV1 à UV9</li>
              </ul>
            </div>
          </div>
        </InfoBubble>
        <div className="flex flex-col sm:flex-row items-center justify-center w-full space-y-2 sm:space-y-0 sm:space-x-2 mb-4">
          <input
            type="text"
            placeholder="Rechercher par nom ou prénom"
            value={searchTerm}
            onChange={handleSearchChange}
            className="p-2 border border-gray-300 rounded w-full sm:w-1/3 dark:bg-gray-800 dark:text-white dark:placeholder-gray-400"
          />
          <select
            value={selectedGroup}
            onChange={handleGroupFilterChange}
            className="p-2 border border-gray-300 rounded w-full sm:w-1/3 dark:bg-gray-800 dark:text-white"
          >
            <option value="">Filtrer par groupe</option>
            {groupList.map((group, index) => (
              <option key={index} value={group}>
                {group}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col sm:flex-row items-center justify-center w-full space-y-2 sm:space-y-0 sm:space-x-2">
          <select
            value={selectedAdherent}
            onChange={(e) => setSelectedAdherent(e.target.value)}
            className="p-2 border border-gray-300 rounded w-full sm:w-1/3 dark:bg-gray-800 dark:text-white"
          >
            <option value="">Sélectionner un adhérent</option>
            {adherentList.map((adherent) => (
              <option key={adherent.id} value={adherent.id}>
                {adherent.nom} {adherent.prenom}
              </option>
            ))}
          </select>
          <Button text="Créer une fiche UV" onClick={handleCreateUV} />
        </div>
      </div>
      <UVTable
        uvData={filteredUV}
        onUVClick={handleUVClick}
        onDeleteUV={onDeleteUV}
      />
      <ToastContainer />
    </div>
  );
};

const UVTable = ({ uvData, onUVClick, onDeleteUV }) => {
  return (
    <div className="bg-gray-100 dark:bg-gray-600 shadow-lg max-w-full mx-auto font-montserrat mt-4 mb-4">
      {/* Mode tableau pour les écrans moyens et grands */}
      <div className="hidden md:block overflow-x-auto px-4">
        <table className="table-auto bg-white dark:bg-slate-300 font-montserrat w-full mx-auto border-collapse border-2 border-slate-300">
          <thead className="bg-gray-800 text-white">
            <tr>
              <th className="px-5 py-3 text-xs font-semibold uppercase tracking-wider text-white">
                Adhérent
              </th>
              <th className="px-5 py-3 text-xs font-semibold uppercase tracking-wider text-white">
                Groupe
              </th>
              {Array.from({ length: 9 }, (_, i) => (
                <th
                  key={`uv${i + 1}`}
                  className="px-5 py-3 text-xs font-semibold uppercase tracking-wider text-white"
                >
                  UV{i + 1}
                </th>
              ))}
              <th className="px-5 py-3 text-xs font-semibold uppercase tracking-wider text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {uvData.length > 0 ? (
              uvData.map((item) => (
                <tr
                  key={item.id}
                  className="hover:bg-gray-100 dark:hover:bg-gray-600 hover:dark:text-white text-sm dark:bg-gray-500 border-b border-gray-200 dark:border-gray-700"
                >
                  <td className="px-5 py-3 text-center">
                    <div className="flex flex-col items-center">
                      <img
                        src={item.adherent?.photo_url}
                        alt={`${item.adherent?.nom} ${item.adherent?.prenom}`}
                        className="w-12 h-12 rounded-full object-cover mb-2"
                      />
                      <span className="text-center">{`${item.adherent?.nom} ${item.adherent?.prenom}`}</span>
                    </div>
                  </td>
                  <td className="px-2 py-1 text-center text-sm">
                    {item.adherent?.adherentGroups
                      ?.map((group) => group.group?.nom) // Récupère les noms des groupes
                      .join(", ")}{" "}
                    {/* Joint les noms par une virgule */}
                  </td>
                  {Array.from({ length: 9 }, (_, i) => (
                    <td
                      key={`uv-cell-${i + 1}`}
                      className="px-5 py-3 text-center cursor-pointer"
                      onClick={() =>
                        onUVClick(item.id, `uv${i + 1}`, item[`uv${i + 1}`])
                      }
                    >
                      {item[`uv${i + 1}`] ? "☑️" : "🔲"}
                    </td>
                  ))}
                  <td className="px-5 py-3 text-center">
                    <ButtonDelete onClick={() => onDeleteUV(item.id)} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="10"
                  className="text-center py-4 px-6 text-gray-800"
                >
                  Aucune UV enregistrée.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Mode card pour les écrans mobiles */}
      <div className="block md:hidden px-4">
        {uvData.length > 0 ? (
          uvData.map((item) => (
            <div
              key={item.id}
              className="bg-white dark:bg-slate-500 shadow-md rounded-lg mb-4 p-4 border border-gray-300 dark:border-gray-600 w-full max-w-full"
            >
              {/* En-tête : Photo, Nom, Prénom */}
              <div className="flex items-center mb-2">
                <img
                  src={item.adherent?.photo_url}
                  alt={`${item.adherent?.nom} ${item.adherent?.prenom}`}
                  className="w-12 h-12 rounded-full object-cover mr-4"
                />
                <div>
                  <div className="text-lg font-semibold">{`${item.adherent?.nom} ${item.adherent?.prenom}`}</div>
                  <div className="text-sm text-gray-600 dark:text-gray-300">
                    {item.adherent?.adherentGroups
                      ?.map((group) => group.group?.nom) // Affiche les vrais noms des groupes
                      .join(", ")}
                  </div>
                </div>
              </div>

              {/* Trait horizontal */}
              <hr className="my-2 border-gray-300 dark:border-gray-600" />

              {/* UVs en flex-row wrap */}
              <div className="flex flex-wrap gap-4 mb-2">
                {Array.from({ length: 9 }, (_, i) => (
                  <div
                    key={`uv-cell-${i + 1}`}
                    className="flex items-center cursor-pointer"
                    onClick={() =>
                      onUVClick(item.id, `uv${i + 1}`, item[`uv${i + 1}`])
                    }
                  >
                    <span className="font-semibold mr-2">UV{i + 1} :</span>
                    <span>{item[`uv${i + 1}`] ? "☑️" : "🔲"}</span>
                  </div>
                ))}
              </div>

              {/* Bouton Supprimer */}
              <div className="flex justify-end">
                <ButtonDelete onClick={() => onDeleteUV(item.id)} />
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-4 text-gray-800 dark:text-white">
            Aucune UV enregistrée.
          </div>
        )}
      </div>
    </div>
  );
};


export default UVPage;
