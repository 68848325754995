import React, { useEffect, useState } from "react";
import useScrollToTop from "../../../hooks/useScollToTop";
import data from "./data_date_historic.json";
import dataceintures from "./data_ceintures_noires.json";
import { getAllPalmares } from "./../../../services/palmaresServices";

const Memento = () => {
  useScrollToTop();
  const [palmares, setPalmares] = useState([]);

  useEffect(() => {
    const fetchPalmares = async () => {
      try {
        const data = await getAllPalmares(); // Récupérer les données des palmarès
        setPalmares(data.slice(0, 6)); // Limiter à 6 derniers palmarès
      } catch (error) {
        console.error("Erreur lors de la récupération des palmarès :", error);
      }
    };

    fetchPalmares();
  }, []);

  return (
    <div className="flex flex-col w-full mx-auto p-4 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      {/* Titre principal */}
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        La Vie du Club
      </h2>

      {/* Contenu principal */}
      <div className="flex flex-col w-full max-w-5xl mx-auto mt-6 text-gray-800 dark:text-gray-300 leading-7">
        {/* Section : Palmarès */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 bg-primary p-2 text-white">
            Palmarès des Judokas
          </h3>
          <p className="mb-6">Palmarès récents et anciens</p>

          {/* Tableau scrollable des derniers palmarès */}
          <div className="mt-6">
            <div className="h-64 overflow-y-auto border border-gray-300 rounded shadow-lg">
              <table className="table-auto w-full text-left border-collapse">
                <thead>
                  <tr className="bg-gray-200 text-gray-700">
                    <th className="p-2 border border-gray-300">Adhérent</th>
                    <th className="p-2 border border-gray-300">Date</th>
                    <th className="p-2 border border-gray-300">Compétition</th>
                    <th className="p-2 border border-gray-300">Classement</th>
                  </tr>
                </thead>
                <tbody>
                  {palmares.map((item) => (
                    <tr key={item.id} className="border-b border-gray-300">
                      {/* Colonne Adhérent (Image + Nom/Prénom) */}
                      <td className="p-2 flex items-center space-x-4">
                        <img
                          src={item.adherent.photo_url}
                          alt={`${item.adherent.nom} ${item.adherent.prenom}`}
                          className="w-10 h-10 rounded-full object-cover"
                        />
                        <span>{`${item.adherent.nom} ${item.adherent.prenom}`}</span>
                      </td>
                      {/* Colonne Date */}
                      <td className="p-2 text-sm">
                        {new Date(item.date_palmares).toLocaleDateString(
                          "fr-FR"
                        )}
                      </td>
                      {/* Colonne Compétition */}
                      <td className="p-2 text-sm">{item.titre_palmares}</td>
                      {/* Colonne Classement */}
                      <td className="p-2 font-bold">
                        {item.classement_palmares}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {/* Boutons pour les années précédentes */}
          <div className="flex flex-wrap gap-4 justify-center mt-6">
            <a
              href="https://docs.google.com/document/d/1V5_41dnIfswBLPfpdvzKQiQZREVFzBA-/edit?usp=drive_link&ouid=117657251292031919027&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 transition"
            >
              2019-2020
            </a>
            <a
              href="https://docs.google.com/document/d/1IECWRtrhgxtWwJpjqDa9r1-z_V3eS72l/edit?usp=drive_link&ouid=117657251292031919027&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 transition"
            >
              2021-2022
            </a>
            <a
              href="https://docs.google.com/document/d/1u1ujUWNnLKHfZhzl36su2TORcLnJF1MY/edit?usp=drive_link&ouid=117657251292031919027&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 transition"
            >
              2022-2023
            </a>
            <a
              href="https://docs.google.com/document/d/1ZOP_ThRzoPIJqsJ3ihJ_lkQS2zEzI3nW/edit?usp=sharing&ouid=117657251292031919027&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 transition"
            >
              2023-2024
            </a>
            <a
              href="https://docs.google.com/document/d/1w2yeF_pKoHcSlPYodmMPqIgKPef8kHaS/edit?usp=sharing&ouid=117657251292031919027&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-500 text-white px-6 py-2 rounded shadow hover:bg-blue-600 transition"
            >
              2024-2025
            </a>
          </div>
        </section>

        {/* Section : Quelques Dates */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 bg-primary p-2 text-white">
            Quelques Dates à retenir
          </h3>
          <p className="mb-6">
            Marcel Pérès, directeur technique et sportif du club pendant 50 ans
          </p>

          {/* Container avec hauteur fixe et scroll */}
          <div className="h-64 overflow-y-auto">
            <table className="min-w-full table-fixed">
              <thead className="bg-gray-300 text-sm">
                <tr className="border-b border-gray-400">
                  <th className="w-1/4 py-2 px-2">Date</th>
                  <th className="w-3/4 py-2 px-2">Événement</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index} className="border-b border-gray-400 text-sm">
                    <td className="py-2 px-2">{item.date}</td>
                    <td className="py-2 px-2">{item.event}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        {/* Section : Ceintures noires formées au club */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 bg-primary p-2 text-white">
            Les Ceintures Noires formées au club
          </h3>
          <p className="mb-6">Par ordre d'ancienneté</p>

          {/* Container avec hauteur fixe et scroll */}
          <div className="h-64 overflow-y-auto">
            <table className="min-w-full table-fixed">
              <thead className="bg-gray-300 text-sm">
                <tr className="border-b border-gray-400">
                  <th className="w-1/6 py-2 px-2">Numéro</th>
                  <th className="w-3/6 py-2 px-2">Nom</th>
                  <th className="w-2/6 py-2 px-2">Date</th>
                </tr>
              </thead>
              <tbody>
                {dataceintures.map((item) => (
                  <tr
                    key={item.numero}
                    className="border-b border-gray-400 text-sm"
                  >
                    <td className="py-2 px-2">{item.numero}</td>
                    <td className="py-2 px-2">{item.nom}</td>
                    <td className="py-2 px-2">{item.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>

        {/* Section : Anniversaires du club */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 bg-primary p-2 text-white">
            Les Anniversaires du Club
          </h3>
          <p className="mb-6">
            Découvrez les célébrations des 40ème et 50ème anniversaires du club.
          </p>

          {/* Container pour les images */}
          <div className="flex flex-wrap gap-4 justify-center">
            {/* Image 40ème anniversaire */}
            <a
              href="https://docs.google.com/document/d/1hfE8nACjMurDQPv4itX3cfmfm4ld0sw2/edit?usp=sharing&ouid=117657251292031919027&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <img
                src="/img/memento/40ans.jpg"
                alt="40ème Anniversaire du club"
                className="h-48 w-auto object-cover rounded shadow-lg hover:shadow-xl transition-shadow duration-200"
              />
              <p className="text-center mt-2 font-medium">40ème Anniversaire</p>
            </a>

            {/* Image 50ème anniversaire */}
            <a
              href="https://docs.google.com/document/d/1PwMCtjcxal9DTSWeOJ4QtVGrhHCQfHtU/edit?usp=sharing&ouid=117657251292031919027&rtpof=true&sd=true"
              target="_blank"
              rel="noopener noreferrer"
              className="block"
            >
              <img
                src="/img/memento/50ans.jpg"
                alt="50ème Anniversaire du club"
                className="h-48 w-auto object-cover rounded shadow-lg hover:shadow-xl transition-shadow duration-200"
              />
              <p className="text-center mt-2 font-medium">50ème Anniversaire</p>
            </a>
          </div>
        </section>

        {/* Section : MARCEL PERES */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 bg-primary p-2 text-white">
            MARCEL PÉRÈS
          </h3>
          <p className="mb-6">
            Marcel Pérès a été le directeur technique et sportif du club de 1974
            à 2024.
          </p>
          <div className="flex flex-col lg:flex-row items-center lg:gap-6">
            <img
              src="/img/memento/marcel_peres.png"
              alt="Marcel Pérès"
              className="w-md max-w-md rounded shadow-md mb-6 lg:mb-0"
            />
            <div className="text-justify">
              <p>
                En 1961, Marcel commence le Judo avec Jacques FEYOX, progression
                du Japonais KAWAISHI, arrivé en France en 1935. <br />
                En 1963, ceinture marron et, grâce à sa famille, il commence
                l’aventure des dojos parisiens pendant les vacances scolaires et
                fera la connaissance tout au long de sa pratique de
                personnalités remarquables et porteuses de messages : Ichiro
                ABBE, MICHIGAMI Maître AWAZU Henri COURTINE et Bernard PARISET.
                <br />
                En 1967, Marcel devient ceinture noire 1er dan. En 1971,
                Champion de France En 1972, athlète du Bataillon de Joinville En
                1973 et 1974, INSEP : son tuteur EPS-Judo est Pierre ALBERTINI,
                En septembre 1974, Marcel devient professeur de Judo. Il
                commence l’enseignement à Segré. <br />
                Ses objectifs sont définis par la discipline : ESPRIT,
                TECHNIQUE, et EFFICACITE. En septembre 2004, création d'un
                regroupement sportif – compétition de l'école A.M.L. : Alliance
                Maine et Loire Judo Ju-Jitsu (A.M.L.)
              </p>
            </div>
          </div>

          {/* Trait fin de séparation en gray-400 sur toute la largeur */}
          <div className="border-t border-gray-400 mt-4"></div>

          <div className="flex flex-col lg:flex-row-reverse items-center lg:gap-6">
            <img
              src="/img/memento/aml.png"
              alt="AML"
              className="w-md max-w-md rounded shadow-md mb-6 lg:mb-0 mt-4"
            />
            <div className="text-justify">
              <p>
                Aujourd’hui, les clubs de cette école sont : <br />
                🔹 ANDARD- BRAIN, <br />
                🔹TREMENTINES, <br />
                🔹LES PONTS DE CE, <br />
                🔹SEGRE
              </p>
            </div>
          </div>

          {/* Trait fin de séparation en gray-400 sur toute la largeur */}
          <div className="border-t border-gray-400 mt-4"></div>

          <div className="flex flex-col lg:flex-row items-center lg:gap-6">
            <img
              src="/img/memento/marcel_peres_6.png"
              alt="Marcel Pérès 6ème dan"
              className="w-md max-w-md rounded shadow-md mb-6 lg:mb-0 mt-4"
            />
            <div className="text-justify">
              <p>Marcel Pérès est aujourd’hui ceinture noire 6ème dan.</p>
              <p>
                Marcel Pérès a cessé ses fonctions de directeur sportif en Juin
                2024 Il a marqué le Judo de nos clubs et celui du Maine et Loire
                pendant 50 ans. Merci Marcel.
              </p>
            </div>
          </div>

          {/* Photo de groupe pleine largeur */}
          <img
            src="/img/memento/marcel_groupe.png"
            alt="Marcel Pérès et le groupe"
            className="w-full mt-6 rounded shadow-md"
          />
        </section>

        {/* Section : Décès */}
        <section className="mb-10">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 bg-primary p-2 text-white">
            Memento - Ils nous ont quittés
          </h3>
          <p className="mb-6">
            N'oublions pas ceux qui ont marqué l'histoire du club et qui nous
            ont quittés.
          </p>
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2">
            Lucien Decremps
          </h3>
          <h2 className="font-semibold mb-4 italic">
            Président et fondateur du club
          </h2>
          <div className="flex flex-col lg:flex-row items-center lg:gap-6">
            <img
              src="/img/memento/lucien_decremps.png"
              alt="Lucien Decremps"
              className="w-full max-w-md rounded shadow-md mb-6 lg:mb-0"
            />
            <div className="text-justify">
              <p>
                Lucien Decremps est arrivé à Segré à l'âge de 35 ans comme
                ingénieur aux Mines de fer (Bois II). Très actif, il participe à
                la création du club de l'Entente Sportive de Nyoiseau (football)
                et de la baignade. Il fonde ensuite le club de judo de Segré en
                septembre 1965. Le club a alors 17 licenciés. La parution au
                Journal Officiel a lieu le 28 octobre. En février 1966, il
                obtient de la direction des Mines, l'utilisation d'une salle sur
                le site du Carreau de l'Oudon. C'est le premier dojo du Judo
                Club Segréen. Il se charge de l'aménagement du dojo, s'occupant
                pratiquement seul des finances, de l'achat du plancher, des
                tatamis, du recrutement des professeurs. <br />
                Sans compter, il accompagne les judokas dans les compétitions et
                avait à cœur de bien gérer le club. Le dojo est transféré au
                Complexe Sportif de Segré en septembre 1974. En 1984, le Dojo
                est agrandi (250 m²) et en janvier 1985, inauguration officielle
                du Dojo Lucien Decremps par Monsieur Glémain, maire de Segré.
                Lucien Decremps laisse le souvenir d'une personnalité attachante
              </p>
            </div>
          </div>
          {/* Trait fin de séparation en gray-400 sur toute la largeur */}
          <div className="border-t border-gray-400"></div>

          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 mt-6">
            Paulette Fouillet
          </h3>
          <h2 className="font-semibold mb-4 italic">
            Grande figure du Judo, Paulette avait commencé le Judo à Segré.
          </h2>
          <div className="flex flex-col lg:flex-row items-center lg:gap-6">
            <img
              src="/img/memento/paulette_fouillet.png"
              alt="Paulette Fouillet"
              className="w-full max-w-md rounded shadow-md mb-6 lg:mb-0"
            />
            <div className="text-justify">
              <p>
                Originaire de Sainte-Gemmes-d’Andigné, Paulette Fouillet était
                une grande figure du judo. Elle avait débuté le Judo à Segré.
                Elle était considérée comme l’une des toutes meilleures
                techniciennes de sa génération et avait emporté les championnats
                d’Europe 1974, 1975 et 1976. À New-York en 1980, pour les
                premiers championnats du monde, elle avait atteint la finale en
                +72 kg et en toutes catégories et avait été déclarée meilleure
                technicienne mondiale. Paulette a été titulaire de la première
                équipe de France féminine, entraineur national, puis cadre
                fédéral au service de la FFJDA. Le 8e dan lui a été attribué.
                Elle avait gardé des attaches profondes avec son club d'origine.
                Paulette est décédée le 25 juillet 2015.
              </p>
            </div>
          </div>

          {/* Trait fin de séparation en gray-400 sur toute la largeur */}
          <div className="border-t border-gray-400"></div>

          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold mb-2 mt-6">
            Cécile Tenailleau
          </h3>
          <h2 className="font-semibold mb-4 italic">
            Une championne au cœur d'or
          </h2>
          <div className="flex flex-col lg:flex-row items-center lg:gap-6">
            <img
              src="/img/memento/cecile_tenailleau.png"
              alt="Cécile Tenailleau"
              className="w-md max-w-md rounded shadow-md mb-6 lg:mb-0"
            />
            <div className="text-justify">
              <p>
                Cécile a marqué l'histoire notre club. Cécile Tenailleau est
                décédée, le vendredi 6 septembre 2019, à l'âge de 61 ans, suite
                à un cancer très agressif. Ceinture noire N°2 du club en 1977,
                elle avait été plusieurs fois 3ème au championnat de France
                seniors. Sollicitée par le haut niveau, elle était partie à
                Paris et avait été membre de l'équipe de France. Les
                entrainements, durs, à l'INSEP étaient alors son quotidien,
                parallèlement à ses études d'infirmière. Discrète, accueillante,
                positive, Cécile aimait aider les gens. Depuis plusieurs années,
                elle était revenue dans son club d'origine et pratiquait
                régulièrement et avec grand plaisir le ne-waza, le vendredi
                soir.
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Memento;
