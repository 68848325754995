import React, { useEffect, useState } from "react";
import { getAllPalmares } from "../../services/palmaresServices";

const LastPalmares = () => {
  const [palmares, setPalmares] = useState([]);

  useEffect(() => {
    const fetchPalmares = async () => {
      try {
        const data = await getAllPalmares(); // Récupérer les données des palmarès
        setPalmares(data.slice(0, 6)); // Limiter à 6 derniers palmarès
      } catch (error) {
        console.error("Erreur lors de la récupération des palmarès :", error);
      }
    };

    fetchPalmares();
  }, []);

  return (
    <div className="container mx-auto py-8">
      <h2 className="text-3xl font-extrabold text-center mt-2 font-montserrat uppercase">
        Derniers Palmarès du Club
      </h2>

      {/* Tableau en mode desktop */}
      <div className="hidden lg:block mt-6">
        <table className="table-auto w-full text-left border-collapse border border-gray-300 dark:border-gray-600">
          <thead>
            <tr className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-200">
              <th className="border border-gray-300 dark:border-gray-600 p-2 dark:text-white">
                Adhérent
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 dark:text-white">
                Date
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 dark:text-white">
                Compétition
              </th>
              <th className="border border-gray-300 dark:border-gray-600 p-2 dark:text-white">
                Classement
              </th>
            </tr>
          </thead>
          <tbody>
            {palmares.map((item) => (
              <tr
                key={item.id}
                className="border-b border-gray-300 dark:border-gray-600 dark:bg-gray-500"
              >
                {/* Colonne Adhérent (Image + Nom/Prénom) */}
                <td className="p-2 flex items-center space-x-4">
                  <img
                    src={item.adherent.photo_url}
                    alt={`${item.adherent.nom} ${item.adherent.prenom}`}
                    className="w-10 h-10 rounded-full object-cover"
                  />
                  <span>{`${item.adherent.nom} ${item.adherent.prenom}`}</span>
                </td>
                {/* Colonne Date */}
                <td className="p-2 text-sm">
                  {new Date(item.date_palmares).toLocaleDateString("fr-FR")}
                </td>
                {/* Colonne Compétition */}
                <td className="p-2 text-sm">{item.titre_palmares}</td>
                {/* Colonne Classement */}
                <td className="p-2 font-bold">{item.classement_palmares}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Mini-cards en mode mobile */}
      <div className="grid grid-cols-2 gap-4 mt-6 lg:hidden px-4">
        {palmares.map((item) => (
          <div
            key={item.id}
            className="bg-white dark:bg-gray-800 shadow-md rounded-lg p-4 flex flex-col items-center text-center"
          >
            {/* Avatar */}
            <img
              src={item.adherent.photo_url}
              alt={`${item.adherent.nom} ${item.adherent.prenom}`}
              className="w-16 h-16 rounded-full object-cover mb-4"
            />
            {/* Nom et Prénom */}
            <h3 className="text-sm font-bold text-gray-800 dark:text-white">
              {item.adherent.nom} {item.adherent.prenom}
            </h3>
            {/* Date de la Compétition */}
            <p className="text-sm text-gray-500 dark:text-gray-400">
              {new Date(item.date_palmares).toLocaleDateString("fr-FR")}
            </p>
            {/* Nom de la Compétition */}
            <p className="text-xs text-gray-700 dark:text-gray-300 mt-2">
              {item.titre_palmares}
            </p>
            {/* Classement */}
            <p className="text-lg font-bold text-blue-500 dark:text-blue-400 mt-2">
              {item.classement_palmares}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LastPalmares;
